import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Service.css";
import { Card } from "react-bootstrap/esm";
import signage from "../assets/signage.jpg";
import ledsign from "../assets/ledsign.jpg";
import digital from "../assets/DigitalPrinting.jpg";
import signboard from "../assets/signboard.jpg";
import marketting from "../assets/carousel-2.jpg";
import customboard from "../assets/customboard.jpg";
import { Link } from "react-router-dom";

const OurServices = () => {
  const projectData = [
    {
      projectTitle: "Signage Services in Bangalore | jr Signage",
      projectImage: signage,
      path: "/signage-services",
    },
    {
      projectTitle: "Led Services in Bangalore | jr Signage",
      projectImage: ledsign,
      path: "/led-services",
    },
    {
      projectTitle: "Printing Services in Bangalore | jr Signage",
      projectImage: digital,
      path: "/printing-services",
    },
    {
      projectTitle: "Marketing Materials in Bangalore | jr Signage",
      projectImage: marketting,
      path: "/marketing-materials",
    },
    {
      projectTitle: "Sign Boards in Bangalore | jr Signage",
      projectImage: signboard,
      path: "/signboard-services",
    },
    {
      projectTitle: "Custom Boards in Bangalore | jr Signage",
      projectImage: customboard,
      path: "/custom-board-services",
    },
  ];
  return (
    <div className="project-container">
      <Container id="services">
        <Row>
          <Col className="project-content">
            <h2 className="project-heading">
              Explore All <span className="service-span">Our Services</span>
            </h2>
            <p className="project-para">
              Our Commitment to Quality and Community
            </p>
          </Col>
        </Row>
        <Row className="mt-5">
          {projectData.map((item, index) => (
            <Col lg={4} md={6} key={index} className="mb-4">
              <Link to={item.path} className="text-decoration-none">
                {/* Wrap with Link */}
                <Card className="project-card-items">
                  {item.projectImage && (
                    <div className="image-wrapper">
                      <Card.Img
                        variant="top"
                        src={item.projectImage}
                        alt={item.projectTitle}
                        className="project-image"
                      />
                    </div>
                  )}

                  <Card.Body className="card-body">
                    <Card.Title className="project-title ">
                      {item.projectTitle}
                    </Card.Title>
                    <Card.Link href="#" className="view-cast-link">
                      Explore All Services
                      <i className="bi bi-chevron-right icon"></i>
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default OurServices;
