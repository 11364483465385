import React from "react";
import { Button } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp-like icon from lucide-react
import "../App.css"; // Or your preferred CSS file

function WhatsAppButton() {
  return (
    <Button
      href="https://wa.me/+918073320068" // Replace with your WhatsApp contact link
      target="_blank"
      className="whatsapp-btn"
      variant="success"
    >
      <FaWhatsapp size={30} />
    </Button>
  );
}

export default WhatsAppButton;
