import { Container, Row, Col } from "react-bootstrap";
import CommonService from "../CommonService";
import ServiceHead from "../ServiceHead";
import ServiceScroll from "../ServiceScrool";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import corporateGifts from "../../assets/IMAGES/Archive/corporateGifts.jpg";
import standupSteels from "../../assets/IMAGES/Archive/standupSteels.jpeg";
import tshirtPrinting from "../../assets/IMAGES/Archive/tshirtPrinting.jpg";
import mugprint from "../../assets/IMAGES/Archive/MugPrinting.jpg";
import corporatescroll from "../../assets/scrollImages/gift.jpg";
import standupSteelscroll from "../../assets/scrollImages/stand.jpg";
import tshirtPrintingscroll from "../../assets/scrollImages/tshirt.jpg";
import mugprintscroll from "../../assets/scrollImages/mug.jpg";
import { Helmet } from "react-helmet-async";

const CustomBoardServiceData = [
  {
    title: "Corporate Gifts",
    imgSrc: corporateGifts,
    points: [
      {
        head: "Personalized Gifts",
        text: "Customizable with your branding and message.",
      },
      {
        head: "Wide Range of Options",
        text: "Includes pens, notebooks, keychains, and more.",
      },
      {
        head: "High-Quality Printing",
        text: "Ensures your brand looks professional and polished.",
      },
      {
        head: "Memorable Impact",
        text: "Leaves a lasting impression on clients and employees.",
      },
    ],
  },
  {
    title: "Rollup Standees",
    imgSrc: standupSteels,
    points: [
      {
        head: "Easy to transport and set up",
        text: "Easy to transport and set up.",
      },
      { head: "High-Quality Prints", text: "Vibrant and durable banners." },
      {
        head: "Custom Sizes",
        text: "Available in various dimensions to fit different needs.",
      },
      {
        head: "Reusable",
        text: "Can be used multiple times for various events and promotions.",
      },
    ],
  },
  {
    title: "T-Shirt Printing",
    imgSrc: tshirtPrinting,
    points: [
      {
        head: "Custom Designs",
        text: "Personalized T-shirts for businesses, events, and personal use.",
      },
      {
        head: "Durable Prints",
        text: "High-quality printing methods ensure long-lasting designs.",
      },
      {
        head: "Variety of Options",
        text: "Different styles, sizes, and colors available.",
      },
      {
        head: "Comfortable Fabrics",
        text: "Made from high-quality materials for a comfortable fit.",
      },
    ],
  },
  {
    title: "Mug Printing",
    imgSrc: mugprint,
    points: [
      {
        head: "Personalized Mugs",
        text: "Custom prints for gifts, promotions, and personal use.",
      },
      {
        head: "High-Quality Prints",
        text: "Dishwasher-safe and vibrant designs.",
      },
      {
        head: "Various Styles",
        text: "Available in different sizes and shapes.",
      },
      {
        head: "Durable Materials",
        text: "Made to withstand daily use and washing.",
      },
    ],
  },
];

const services = [
  { serviceName: "Corporate Gifts", serviceImage: corporatescroll },
  { serviceName: "Rollup Standees", serviceImage: standupSteelscroll },
  { serviceName: "T-Shirt Printing", serviceImage: tshirtPrintingscroll },
  { serviceName: "Mug Printing", serviceImage: mugprintscroll },
];

function CustomBoardService() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Custom Board Services in Bangalore | JR Signage</title>
        <meta
          name="description"
          content="JR Signage in Bangalore offers top-notch custom board services, including corporate gifts, roll-up standees, t-shirt printing, and mug printing"
        />
        <meta
          name="keywords"
          content="custom board services, corporate gifts Bangalore, rollup standees, t-shirt printing Bangalore, mug printing Bangalore, personalized gifts Bangalore, custom signage Bangalore, promotional products, brand merchandise, printing solutions Bangalore"
        />
        <link
          rel="canonical"
          href="https://jrsignage.in/custom-board-services"
        />
        Sign Board Services in Bangalore | JR Signage
      </Helmet>
      <ServiceHead />
      <Container className="mt-5">
        <Row>
          <Col>
            <ServiceScroll
              services={services}
              title="Our Custom-Board"
              customClass="homepage-service-scroll"
            />
          </Col>
        </Row>
        <CommonService serviceData={CustomBoardServiceData} serviceTitle="" />
      </Container>
    </>
  );
}

export default CustomBoardService;
