import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import NavBar from "./Components/Header/NavBar";
import AboutUs from "./Pages/AboutUs/AboutUs";
import HomeScreen from "./Pages/Home/Home";
import SignageService from "./Services/ServicesList/SignageService";
import ContactFooter from "./Pages/ContactUs.jsx/ContactFooter";
import LedService from "./Services/ServicesList/LedService";
import MarkettingService from "./Services/ServicesList/MarkettingService";
import CustomBoardService from "./Services/ServicesList/CustomBoardService";
import SigBoardService from "./Services/ServicesList/SignBoards";
import PrintingService from "./Services/ServicesList/PrintingService";
import Loader from "./Components/Loader";
import ContactUsDetails from "./Pages/ContactDeatils/ContactUsDetails";
import WhatsAppButton from "./Components/WhatsApp";
import CallButton from "./Components/CallerIcon";
import TermsConditions from "./Components/TermsConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/signage-services" element={<SignageService />} />
        <Route path="/led-services" element={<LedService />} />
        <Route path="/marketing-materials" element={<MarkettingService />} />
        <Route path="/custom-board-services" element={<CustomBoardService />} />
        <Route path="/signboard-services" element={<SigBoardService />} />
        <Route path="/printing-services" element={<PrintingService />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUsDetails />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <ContactFooter />
      <WhatsAppButton />
      <CallButton />
    </Router>
  );
}

export default App;
