import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./contactus.css";
import ContactHead from "./ContactHead";
import { Helmet } from "react-helmet-async";

const ContactUsDetails = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - JR Signage</title>
        <meta
          name="description"
          content="Contact JR Signage for expert custom signage solutions in Bangalore. From 3D LED signs and acrylic letters and flex banners, we specialize in creating high-quality, eye-catching signage tailored to your brand's needs."
        />
        <meta
          name="keywords"
          content="Contact JR Signage,Custom signage Bangalore,3D LED signs,Acrylic letter signs Bangalore,Flex banners Bangalore,Signage solutions Bangalore,Branding signage,Custom business signs,Durable signage designs near by"
        />
        <link rel="canonical" href="https://jrsignage.in/contact-us" />
      </Helmet>
      <ContactHead />
      <Container className="py-5">
        <Row>
          <h2 className="text-center fw-bold mb-5">
            If You Have Any Query, Please Feel Free{" "}
            <span className="contact-headSpan"> Contact Us</span>
          </h2>
          <Col xs={12} md={8} className="mx-auto">
            <Form>
              <Row>
                {/* Name Field */}
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>
                </Col>
                {/* Email Field */}

                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* Phone Field */}
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                    />
                  </Form.Group>
                </Col>
                {/* Subject Field */}
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group controlId="formSubject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" placeholder="Enter the subject" />
                  </Form.Group>
                </Col>
              </Row>

              {/* Message Field */}
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Write your message here..."
                />
              </Form.Group>

              {/* Order Button */}
              <div className="text-center">
                <Button disabled variant="primary" size="lg">
                  Send Message
                </Button>
              </div>
            </Form>
          </Col>
        </Row>

        {/* Google Map */}
        <Row className="mt-5">
          <Col xs={12} className="mx-auto">
            <h3 className="text-center fw-bold mb-4">
              Find Us <span className="contact-headSpan">Here</span>{" "}
            </h3>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!4v1737471209078!6m8!1m7!1snLBDVbA8IsYbcmONs4UlMg!2m2!1d13.01036127388805!2d77.63596908441669!3f156.15330708661418!4f-23.026771653543307!5f0.4000000000000002"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Map"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUsDetails;
