import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link
import "./NavBar.css";
import logo from "../../assets/logoJr.png";
import Loader from "../Loader";

function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Navbar expand="lg" sticky="top" expanded={expanded} className={"navbar"}>
        <Container>
          <Navbar.Brand as={Link} to="/" className="navbar-brand">
            <Image src={logo} alt="logo" className="nav-logo" fluid />
            <h1 className="LogoHead pt-2 pt-sm-0">JRSIGNAGE</h1>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
            style={{
              border: "1px solid #6c757d",
              backgroundColor: "transparent",
              outline: "none",
              boxShadow: "none",
            }}
          >
            {expanded ? (
              <FaTimes size={24} />
            ) : (
              <span className="navbar-toggler-icon"></span>
            )}
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-start">
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                Home
              </Nav.Link>
              <Nav.Link
                href="#services"
                as={Link}
                to="/"
                onClick={(e) => {
                  if (window.location.pathname === "/") {
                    e.preventDefault(); // Prevent navigation if already on the same page
                    document
                      .getElementById("services")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }
                  setExpanded(false);
                }}
              >
                Services
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/about-us"
                onClick={() => setExpanded(false)}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                onClick={() => setExpanded(false)}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
