import { Container, Row, Col } from "react-bootstrap";
import "./ServiceList.css";
import ServiceScroll from "../ServiceScrool";
import ServiceHead from "../ServiceHead";
import frontlit from "../../assets/IMAGES/Archive/frontlit3d.jpeg";
import Backlit from "../../assets/IMAGES/Archive/Backlit-2.jpg";
import stainless from "../../assets/IMAGES/Archive/stainless.jpg";
import acrylic from "../../assets/IMAGES/Archive/acrylic 3d.jpg";
import wooden from "../../assets/IMAGES/Archive/wooden3d.jpg";
import aluminium from "../../assets/IMAGES/Archive/aluminium3d.jpg";
import flexface from "../../assets/flex.jpg";
import CommonService from "../CommonService";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import frontlitscroll from "../../assets/scrollImages/frontlit.jpg";
import Backlitscroll from "../../assets/scrollImages/backlit.jpg";
import stainlessscroll from "../../assets/scrollImages/stainless.jpeg";
import woodenscroll from "../../assets/scrollImages/wooden.jpg";
import aluminiumscroll from "../../assets/scrollImages/aluminium.jpg";
import arclicscroll from "../../assets/IMAGES/Archive/Arclyic-1.jpg";
import { Helmet } from "react-helmet-async";
function SignageService() {
  const signageServiceData = [
    {
      title: "Frontlit 3D Letter Signage",
      imgSrc: frontlit,
      points: [
        {
          head: "Premium Design",
          text: "Stylish front-lit acrylic or metal letters with bright illumination.",
        },
        {
          head: "Tailored Customization",
          text: "Customize size, color, and style to enhance your brand.",
        },
        {
          head: "Effortless Installation",
          text: "Seamlessly integrates with lighting for maximum visibility.",
        },
        {
          head: "Easy Maintenance",
          text: "Stays bright and eye-catching with minimal upkeep.",
        },
      ],
    },
    {
      title: "Backlit 3D Letter Signage",
      imgSrc: Backlit,
      points: [
        {
          head: "Bold Design",
          text: "Durable materials like acrylic or metal for lasting quality.",
        },
        {
          head: "Vibrant Lighting",
          text: "Energy-efficient LED backlighting for night and day visibility.",
        },
        {
          head: "Compact Power",
          text: "Hidden power supply for a clean, professional look.",
        },
        {
          head: "High Visibility",
          text: "Visible from a distance, ensuring your brand shines.",
        },
      ],
    },
    {
      title: "Stainless Steel Signage",
      imgSrc: stainless,
      points: [
        {
          head: "Lasting Quality",
          text: "Corrosion-resistant steel for all-weather durability.",
        },
        {
          head: "Sleek Finish",
          text: "Modern and professional to boost your business's image.",
        },
        {
          head: "Custom Options",
          text: "Personalize designs and finishes to fit your brand.",
        },
        {
          head: "Eco-Friendly",
          text: "Sustainable, recyclable material appeals to green values.",
        },
      ],
    },
    {
      title: "Acrylic 3D Letter Signage",
      imgSrc: acrylic,
      points: [
        {
          head: "Versatile Material",
          text: "Lightweight and durable with multiple color options.",
        },
        {
          head: "Endless Shapes",
          text: "Customizable to match your unique brand style.",
        },
        {
          head: "Vibrant Colors",
          text: "Full range of colors to bring your design to life.",
        },
        {
          head: "Finishing Touches",
          text: "Options like polished or frosted edges for a standout look.",
        },
      ],
    },
    {
      title: "Wooden 3D Letter Signage",
      imgSrc: wooden,
      points: [
        { head: "Warm Aesthetic", text: "Crafted for a rustic, natural look." },
        {
          head: "Smooth Finish",
          text: "Sanded and polished for a quality feel.",
        },
        {
          head: "Unique Colors",
          text: "Custom paint or stain adds personal flair.",
        },
        {
          head: "Perfect Details",
          text: "Final touches for a flawless presentation.",
        },
      ],
    },
    {
      title: "Aluminium 3D Letter Signage",
      imgSrc: aluminium,
      points: [
        {
          head: "Light & Strong",
          text: "High-quality aluminum that’s durable and lightweight.",
        },
        {
          head: "Various Finishes",
          text: "Choose brushed, polished, or painted to suit your style.",
        },
        {
          head: "Custom Designs",
          text: "Cut into any shape or font with precision.",
        },
        {
          head: "Vibrant Colors",
          text: "Painted or natural metal options for a sleek finish.",
        },
      ],
    },
    {
      title: "Flex Face Signage",
      imgSrc: flexface,
      points: [
        {
          head: "Flexible Design",
          text: "Stretchable PVC material that’s easy to work with.",
        },
        {
          head: "Weatherproof",
          text: "Built to withstand rain, wind, and UV exposure.",
        },
        {
          head: "Multi-Use",
          text: "Perfect for storefronts, billboards, and more.",
        },
        {
          head: "Custom Graphics",
          text: "Personalized high-quality graphics for unique branding.",
        },
      ],
    },
  ];

  const services = [
    { serviceName: "Frontlit 3D Letter Signage", serviceImage: frontlitscroll },
    { serviceName: "Backlit 3D Letter Signage", serviceImage: Backlitscroll },
    { serviceName: "Stainless Steel Signage", serviceImage: stainlessscroll },
    { serviceName: "Acrylic 3D Letter Signage", serviceImage: arclicscroll },
    { serviceName: "Wooden 3D Letter Signage", serviceImage: woodenscroll },
    {
      serviceName: "Aluminium 3D Letter Signage",
      serviceImage: aluminiumscroll,
    },
    { serviceName: "Flex Face Signage", serviceImage: flexface },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Signage Services in Bangalore | JR Signage</title>
        <meta
          name="description"
          content="JR Signage offers premium signage services in Bangalore, specializing in custom 3D LED signs, acrylic lettering,, flex banners, and backlit signage."
        />
        <meta
          name="keywords"
          content="Signage services Bangalore, 3D letter signage, Backlit signage, Frontlit signage, Stainless steel signage, Acrylic letter signage, Wooden 3D signage, Aluminium signage, Flex face signage, Custom signs Bangalore"
        />
        <link rel="canonical" href="https://jrsignage.in/signage-servicess" />
      </Helmet>
      <ServiceHead />
      <Container className="mt-5">
        <Row>
          <Col>
            <ServiceScroll
              services={services}
              title="Our Signage"
              customClass="homepage-service-scroll"
            />
          </Col>
        </Row>
        <CommonService serviceData={signageServiceData} serviceTitle="" />
      </Container>
    </>
  );
}

export default SignageService;
