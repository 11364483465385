import { Container, Row, Col } from "react-bootstrap";
import "./ServiceList.css";
import ServiceScroll from "../ServiceScrool";
import ServiceHead from "../ServiceHead";
import CommonService from "../CommonService";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import DigitalPrinting from "../../assets/IMAGES/Archive/DigitalPrinting.jpg";
import offSetPrinting from "../../assets/IMAGES/Archive/offSetPrinting-1.jpg";
import screenPrint from "../../assets/IMAGES/Archive/screenPrint.jpg";
import EcoSolvent from "../../assets/IMAGES/Archive/EcoSolvent-1.jpeg";
import flexbanner from "../../assets/IMAGES/Archive/flex&Banner.jpeg";
import canvas from "../../assets/IMAGES/Archive/canvas.jpg";
import DigitalPrintingscroll from "../../assets/scrollImages/digital.jpg";
import offSetPrintingscroll from "../../assets/scrollImages/offset.jpg";
import screenPrintcroll from "../../assets/scrollImages/screen.jpg";
import canvasscroll from "../../assets/scrollImages/canvas.jpg";
import { Helmet } from "react-helmet-async";
function PrintingService() {
  const printingServiceData = [
    {
      title: "Digital Printing",
      imgSrc: DigitalPrinting,
      points: [
        {
          head: "High-Quality Prints",
          text: "Digital printing produces crisp and vibrant images, ideal for marketing materials, business cards, and posters.",
        },
        {
          head: "Fast Turnaround",
          text: "LED Quick production times make it perfect for urgent projects.",
        },
        {
          head: "Cost-Effective",
          text: "Economical for small print runs, reducing waste and costs.",
        },
        {
          head: "Customization",
          text: "Allows for easy personalization and variable data printing, such as unique names or addresses on each print.",
        },
      ],
    },
    {
      title: "Offset Printing",
      imgSrc: offSetPrinting,
      points: [
        {
          head: "Sharp and Clean Images",
          text: "Delivers precise and high-resolution prints, suitable for magazines, brochures, and large print volumes.",
        },
        {
          head: "Cost-Effective for Large Runss",
          text: "Economical for high-volume jobs, reducing per-unit costs.",
        },
        {
          head: "Consistent Quality",
          text: "Ensures uniform color and quality throughout the print run.",
        },
        {
          head: "Wide Range of Paper Options",
          text: "Various paper types and finishes available to suit different needs and preferences.",
        },
      ],
    },

    {
      title: "Screen Printing",
      imgSrc: screenPrint,
      points: [
        {
          head: "Durability",
          text: "Produces long-lasting and vibrant prints, ideal for textiles and promotional items.",
        },
        {
          head: "Versatility",
          text: "Can be used on various materials like fabric, plastic, and metal.",
        },
        {
          head: "Vivid Colors",
          text: "Achieves bright and opaque colors, perfect for T-shirts and posters.",
        },
        {
          head: "Cost-Effective for Large Batches",
          text: "Economical for bulk orders, making it ideal for promotional giveaways.",
        },
      ],
    },
    {
      title: "Eco Solvent Printing",
      imgSrc: EcoSolvent,
      points: [
        {
          head: "Environmentally Friendly",
          text: "Uses eco-solvent inks that are less harmful to the environment.",
        },
        {
          head: "Weather Resistant",
          text: "Prints are durable and can withstand outdoor conditions without fading..",
        },
        {
          head: "High-Quality Output",
          text: "Produces sharp and vibrant images suitable for banners and vehicle wraps..",
        },
        {
          head: "Low Maintenance",
          text: "Eco-solvent printers require less cleaning and maintenance, reducing overall costs.",
        },
      ],
    },
    {
      title: "High-Quality Cloth Banner Printing",
      imgSrc: flexbanner,
      points: [
        {
          head: "Durability",
          text: "Made from robust materials that can endure wear and tear.",
        },
        {
          head: "Washable",
          text: "Easy to clean and maintain, ensuring longevity.",
        },
        {
          head: "Vibrant Colors",
          text: "High-resolution prints with striking colors, perfect for events and trade shows.",
        },
        {
          head: "Custom Sizes and Finishes",
          text: "Available in various dimensions and finishes to meet specific requirements.",
        },
      ],
    },
    {
      title: "Canvas Printing",
      imgSrc: canvas,
      points: [
        {
          head: "Artistic Quality",
          text: "Provides a textured, gallery-like finish, ideal for photos and custom art.",
        },
        {
          head: "HDurability",
          text: "Resistant to fading and environmental damage.",
        },
        {
          head: "Custom Sizes",
          text: "Available in various dimensions to suit different needs and spaces.",
        },
        {
          head: "Ready-to-Hang Options",
          text: "Comes with mounting hardware for easy display.",
        },
      ],
    },
  ];
  const services = [
    {
      serviceName: "Digital Printing",
      serviceImage: DigitalPrintingscroll,
    },
    {
      serviceName: "Offset Printing",
      serviceImage: offSetPrintingscroll,
    },
    { serviceName: "Screen Printing", serviceImage: screenPrintcroll },
    { serviceName: "Eco Solvent Printing", serviceImage: EcoSolvent },
    {
      serviceName: "High-Quality Cloth Banner Printing",
      serviceImage: flexbanner,
    },
    {
      serviceName: "Canvas Printing",
      serviceImage: canvasscroll,
    },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Printing Services in Bangalore | JR Signage</title>
        <meta
          name="description"
          content="JR Signage offers premium printing services in Bangalore, specializing in high-quality custom signs, flex banners, Eco Solvent Printing, acrylic letters, and more."
        />
        <meta
          name="keywords"
          content="Printing services Bangalore,Flex banner printing Bangalore,Banner printing Bangalore,Signage solutions Bangalore,Digital printing Bangalore,High-quality printing Bangalore,Eco Solvent Printing Bangalore,Offset Printing Bangalore, Canvas Printing Bangalore, Screen Printing Bangalore"
        />
        <link rel="canonical" href="https://jrsignage.in/printing-services" />
      </Helmet>
      <ServiceHead />
      <Container className="mt-5">
        <Row>
          <Col>
            <ServiceScroll
              services={services}
              title="Our Printing"
              customClass="homepage-service-scroll"
            />
          </Col>
        </Row>
        <CommonService serviceData={printingServiceData} serviceTitle="" />
      </Container>
    </>
  );
}

export default PrintingService;
