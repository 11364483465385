import React from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

const TermsConditions = () => {
  return (
    <Container>
      <Row className="my-5">
        <Col>
          <h1 className="text-center">Terms & Conditions</h1>
          <Card>
            <Card.Body>
              <h4>Introduction</h4>
              <p>
                By using our signage and flex banner services, you agree to the
                following terms and conditions.
              </p>

              <h4>Services</h4>
              <p>
                We provide professional signage and flex banner services for
                businesses, events, and more.
              </p>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Pricing and Payment</Accordion.Header>
                  <Accordion.Body>
                    All prices are quoted in INR, and payment must be made in
                    full before the production of the signage.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Intellectual Property</Accordion.Header>
                  <Accordion.Body>
                    All designs and content are the property of JR Signage
                    unless otherwise stated.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Liability</Accordion.Header>
                  <Accordion.Body>
                    We are not responsible for any damages or losses incurred
                    due to incorrect information provided by the client.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <h4 className="mt-3">Contact Information</h4>
              <p>
                If you have any questions about our terms and conditions, please
                contact us at{" "}
                <a href="mailto:jrsignage2420@gmail.com">
                  jrsignagegnanaraji@gmail.com
                </a>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsConditions;
