import React from "react";
import { Button } from "react-bootstrap";
import { Phone } from "lucide-react"; // WhatsApp call icon
import "../App.css"; // Or your preferred CSS file

function CallButton() {
  return (
    <Button
      href="tel:+918073320068" // Replace with your phone number for calling
      target="_blank"
      className="whatsapp-call-btn"
      variant="primary"
    >
      <Phone size={30} />
    </Button>
  );
}

export default CallButton;
